import request from '@/utils/request'
import Qs from 'qs'

// 获取七牛上传的token
// export function qnToken(params) {
//   return request({
//     url: '/upload/qiniu/getToken',
//     method: 'post',
//     params
//   })
// }

export function qnToken(params) {
  return request({
    url: '/frontend/common/file-upload/token',
    method: 'post',
    data: Qs.stringify(params)
  })
}




export function uploadQiniuForEditer(params, file) {
  console.log('params', file)
  const formData = new FormData();
  formData.append('file', file);
  formData.append('token', params.token);

  return request({
    url: 'https://up.qiniup.com',
    method: 'post',
    header: {
      'Content-type': 'multipart/form-data'
    },
    data: formData
  })
}



// export function editSource(params) {
//   return request({
//     url: '/backend/common/file-upload/save-source',
//     method: 'post',
//     data: Qs.stringify(params)
//   })
// }